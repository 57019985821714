.print-format #header-html {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.print-format .letter-head {
  height: 20mm;
}

.print-format .contact-row {
  position: relative;
  left: 20mm;
  width: 170mm;
  height: 45mm;
  top: 15mm;
}

.print-format #contact,
.print-format #contact > table {
  /** Set Font Size for this Row in only one Place */
  /** Fixes Html preview problems with tables */
  font-size: 8pt;
}

.print-format .sender {
  position: relative;
  /* font-size: 75%; */
  /* height: 17.7mm */
  font-size: 6pt !important;
  text-align: left;
  padding-right: 2pt;
  padding-bottom: 1px;
  border-bottom: solid black 1px;
  margin-bottom: 1px;
  white-space: nowrap;
  display: inline-block;
}

.print-format #address {
  float: left;
  width: 95mm;
  padding-left: 5mm;
  overflow: hidden;
  height: 45mm;
}

.print-format #address-border {
  display: block;
  width: 85mm;
  height: 40mm;
  border: 1px solid transparent;
  position: absolute;
  left: 5mm;
  top: 0;
}

.print-format #contact {
  float: right;
  width: 75mm;
  margin-top: -10mm;
}

.print-format #contact > table {
  width: 100%;
}

.print-format #contact td {
  padding: 1.5px !important;
}

.print-format #subject {
  margin-bottom: 2em;
  height: 5mm;
  font-weight: bold;
}

.print-format .din-mark {
  height: 1pt;
  background-color: black;
  position: absolute;
}

.print-format #faltmarke-1 {
  top: 85mm;
  width: 12mm;
}

.print-format #lochmarke {
  top: 128.5mm;
  width: 7mm;
}

.print-format #faltmarke-2 {
  top: 190mm;
  width: 12mm;
}

.clear {
  clear: both;
}

.print-format {
  padding: 0;
  position: relative;
}

.print-format #header {
  width: 100%;
  height: 35mm;
  margin-top: -10mm;
  overflow: hidden;
  position: relative;
  margin-left: 25mm;
  margin-right: 20mm;
}

.print-format .contact-row {
  position: relative;
  overflow: hidden;
  left: 20mm;
  height: 45mm;
  width: 170mm;
}

.print-format #sender {
  position: relative;
  font-size: 75%;
  height: 17.7mm;
}

.print-format #sender p {
  position: absolute;
  width: 85mm;
  bottom: 0;
}

.print-format #address {
  float: left;
  width: 85mm;
  padding-left: 5mm;
}

.print-format #contact {
  float: right;
  width: 75mm;
  margin-top: 5mm;
}

.print-format #contact > table {
  width: 100%;
}

.print-format #contact td {
  padding: 1.5px !important;
}

.print-format #subject {
  margin-bottom: 2em;
  height: 5mm;
  font-weight: bold;
}

.print-format .din-mark {
  height: 1pt;
  background-color: #000;
  position: absolute;
}

.print-format #faltmarke-1 {
  top: 95mm;
  width: 12mm;
}

.print-format #lochmarke {
  top: 138.5mm;
  width: 7mm;
}

.print-format #faltmarke-2 {
  top: 200mm;
  width: 12mm;
}

.print-format #text {
  margin-top: 8.46mm;
  margin-left: 25mm;
  margin-right: 20mm;
}

.print-format .text-right {
  text-align: right;
}

.print-format .text-small {
  font-size: 10pt;
}

.print-format .black-border {
  border-top: solid #000 1px;
  border-bottom: solid #000 1px;
}

.print-format .w-100 {
  width: 100%;
}

.print-format table {
  page-break-inside: auto;
  table-layout: fixed;
}

.print-format table tr {
  page-break-inside: avoid;
  page-break-after: auto;
}

.print-format table thead {
  display: table-header-group;
}

.print-format table tfoot {
  display: table-row-group;
}

.print-format .epilogue {
  text-align: justify;
}

.print-format .epilogue .salutation {
  page-break-inside: avoid;
}

.print-format .ql-editor {
  line-height: unset;
  font-family: unset;
  overflow: hidden;
}

.print-format .ql-editor p {
  margin: unset;
}

.print-format-footer {
  margin-left: 25mm;
  padding-right: 20mm;
}

#pagenum {
  text-align: right;
  margin-right: 20mm;
  margin-top: 4.23mm;
  margin-bottom: 4.23mm;
}

body,
html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}